<template>
	<figure class="image__wrapper" v-lazyload>
		<img
			:class="custom_class"
			alt="random image"
			data-zoomable=""
			:data-url="source"
		>
	</figure>
</template>

<script>
// import mediumZoom from "medium-zoom";

export default {
	name: "ImageCard",
	props: {
		source: {
			type: String,
			required: true
		},
		custom_class: {
			type: String
		}
	},
	mounted() {
		const images = [
			...document.querySelectorAll('[data-zoomable]'),
		]
		// mediumZoom(images, {
		// 	margin: 0,
		// 	'z-index': '999',
		// 	position: 'absolute',
		// 	background: 'transparent',
		// 	scrollOffset: 0,
		// })
	}
}
</script>

<style scoped>
.medium-zoom-image {
	cursor: pointer;
	cursor: zoom-in;
	position: absolute;
	transition: transform .3s cubic-bezier(.2, 0, .2, 1) !important;
	z-index: 99
}
</style>
