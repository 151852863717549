<template>
	<div
		class="flex flex-col bg-white height-card relative grid-view rounded-md dm-sans-font shadow-md relative"
	>
		<div
			class="flex flex-col md:flex-row md:justify-between md:align-items-center space-y-2 p-2 border-b cursor-pointer"
			v-on:click="openProductDetail(product.id)"
		>
			<div class="flex flex-col">
				<span class="text-xs font-semibold text-gray-800">{{
					truncate(
						product.product_name || product.description,
						30,
						"..."
					)
				}}</span>
				<span class="text-xs"
					>{{ $t("articleNumber") }}: {{ product.article_number }}
				</span>
			</div>
		</div>
		<div class="p-2 article_display_height cursor-pointer">
			<div class="row no-gutters">
				<div v-on:click="openProductDetail(product.id)" class="col-6">
					<div class="p-3">
						<div class="flex text-xs text-gray-400" v-if="GET_COMPANY.hide_stock_quantity === false">
							<div v-if="hidden_fields.stock_quantity === false">
							  <span v-if="product.allow_backorder_or_presale" class="preorder-item">
								{{ $t('preOrderItem') }}
							  </span>
							  <span v-else-if="parseInt(product.quantity_in_stock) >= parseFloat(GET_COMPANY.threshold_product_visibility)">
								<span class="">{{ $t('disposableQty') }}</span>:<span class="ml-1">{{ product.quantity_in_stock }}</span>
							  </span>
							  <span v-else>(<span class="font-semibold">{{ $t('unavailable') }}</span>)</span>
							</div>
						</div>
						<div class="flex justify-between space-x-5 mt-3">
							<div
								class="row"
								style="position: relative; bottom: -75px"
							>
								<div class="ml-3">
									<!-- <span
										class="text-gray-500"
										v-if="!product?.has_discount"
									>
										<div class="text-lg w-full">
											{{
												$services.helpers.formatPrice(
													product.discounted_price
												)
											}}
											{{ product.currency }}/styck
										</div>
									</span> -->

									<!-- <span
										class="text-gray-500"
										v-if="
											hasDiscount() === false &&
											hidden_fields.sales_price === false
										"
										>{{
											$services.helpers.formatPrice(
												product.sales_price
											)
										}}
										{{ product.currency }}/styck</span
									> -->
								</div>
								<div class="col-md-12">
									<span
										class="text-gray-500"
										v-if="
											product.hasOwnProperty(
												'standard_price'
											)
										"
										>{{ $t("standard_price") }}:
										{{ product.standard_price }}</span
									>
								</div>
								<div class="text-blue-400 col-md-12">
									<!-- <a class="text-blue-300" target="_blank" :href="product.product_video_url">{{ $t('productVideo') }}</a> -->
									<a
										class="text-blue-300"
										target="_blank"
										:href="product.product_video_url"
										v-if="
											product.product_video_url &&
											product.product_video_url !== 'nan'
										"
									>
										<img
											class="h-10"
											src="./../../../assets/images/icons/vid.png"
											alt="article product image"
										/>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-6">
					<div
						class="flex-col article_display_carousel"
						v-on:click="showImages"
					>
						<el-carousel
							:interval="5000"
							indicator-position="none"
							v-if="product.images.length > 0"
						>
							<el-carousel-item
								v-for="(item, i) in product.images"
								:key="i"
							>
								<span class="img">
									<ImageCard
										:source="item.image"
										:custom_class="'carousel-cell-image'"
									></ImageCard>
								</span>
							</el-carousel-item>
						</el-carousel>
					</div>
				</div>
			</div>
		</div>
		<div
			v-if="product?.has_discount && Number(product?.discount_value) > 0"
		>
			<del class="flex justify-center text-lg"
				>{{ product?.sales_price }} {{ product?.currency }} </del
			>
			<span
				class="!text-red-500 font-bold flex justify-center items-center gap-4"
			>
				{{ $t("discounted") }}
				<span class="font-black text-[.8rem]">
					{{
						convertDiscountValueToPercentage(
							product?.discount_value,
							product?.discount_type,
							product.sales_price
						)
					}}%</span
				>
			</span>
			<div class="flex justify-center font-bold text-lg">
				{{ product?.discounted_price }} 
			</div>
		</div>
		<div v-else class="mt-[3.1rem]">
			<div class="flex justify-center font-bold text-lg">{{product?.sales_price}} {{ product?.currency }} </div>
		</div>

		<div class="pt-2 pb-2 pl-2 pr-2 border-t button-action-pos">
			<div class="grid grid-cols-2 gap-2 md:grid-cols-2 gap-2 lg:grid-cols-2 gap-2">
			  <div>
				<input-number @onchange="onChangeInput" :num="num" />
			  </div>
			  <div class="flex justify-end md:justify-end lg:justify-end w-full">
				<button
				  class="border-2 hover:bg-yellow-500 hover:text-white border-yellow-500 px-4 py-1 text-yellow-500 rounded-md"
				  @click="addProductCart"
				>
				  {{ $t('buyProduct') }}
				</button>
			  </div>
			</div>
		  </div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

import ImageCard from "../../../customComponent/ImageCard";

import inputNumber from "./inputNumber";
import { BUYER_HIDDEN_FIELDS } from "../../../services/constant";
import {
	getUserSetting,
	getUserSettingHideFieldStatus,
} from "../../../services/storage-window";
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
import Vue from "vue";
import { convertDiscountValueToPercentage } from "../../../utils/utils";

Vue.use(VueViewer);
export default {
	name: "article-component",
	components: { ImageCard, inputNumber },
	props: {
		product: {
			type: Object,
			required: true,
		},
		limit: {
			default: 20,
		},
		pagenum: {
			default: 1,
		},
		searchname: {
			default: "",
		},
		is_recommended: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	data() {
		return {
			convertDiscountValueToPercentage,
			num: 0,
			prevNum: 0,
			imageBaseServerURL: "https://api.b2bportalen.se/media/",
			flickityOptions: {
				initialIndex: 3,
				prevNextButtons: true,
				pageDots: false,
				wrapAround: false,
				draggable: ">1",
				fullscreen: true,

				// any options from Flickity can be used
			},
			hidden_fields: {
				...JSON.parse(JSON.stringify(BUYER_HIDDEN_FIELDS)),
			},
			productImages: [],
		};
	},
	computed: {
		...mapGetters({
			GET_ARTICLES: "customer/GET_ARTICLES",
			CART_PRODUCT: "customer/CART_PRODUCT",
			GET_COMPANY: "customer/GET_COMPANY_SETTINGS",
			BUYER_SETTINGS: "customer/GET_USER_SETTINGS",
			HIDE_FIELD_STATUS: "customer/GET_USER_SETTINGS_HIDE_FIELD_STATUS",
		}),
	},
	watch: {},
	mounted() {
		window.Bus.$on("revert-input-to-zero", (e) => {
			this.num = 0;
		});
		this.disabledFields(getUserSettingHideFieldStatus());
		window.Bus.$on("trigger-hidden-field-event", (e) => {
			this.disabledFields(e);
		});
		this.productImages = [];
		if (this.product.images.length > 0) {
			this.product.images.map((item) => {
				this.productImages.push(item.image);
			});
		}
	},
	methods: {
		truncate: function (text, length, suffix) {
			if (text.length > length) {
				return text.substring(0, length) + suffix;
			} else {
				return text;
			}
		},

		handleAddToCart(product, qty) {
			let currentDate = moment().format("YYYY-MM-DD");
			let productLoad = {
				product,
				qty: qty || 1,  // Default quantity to 1 if qty is not set
				apply_discount: false,
			};

			// Check stock threshold and notify if out of stock
			if (this.GET_COMPANY.apply_stock_threshold === true && !product.allow_backorder_or_presale) {
				if (parseFloat(product.quantity_in_stock) <= parseFloat(this.GET_COMPANY.threshold_product_visibility)) {
					this.$services.helpers.notification(this.$t('outOfStock'), 'error', this);
					return false;
				}
			}

			// Out-of-stock check
			if (!product.allow_backorder_or_presale && parseFloat(product.quantity_in_stock) <= 0) {
				this.$services.helpers.notification(this.$t('outOfStock'), 'error', this);
				return false;
			}

			const totalPrice = parseFloat(product.sales_price * qty);

			// Minimum order quantity check
			if (product.minimum_order_quantity && qty < product.minimum_order_quantity) {
				return this.$services.helpers.notification(
					`${this.$t("customerMininmumOrderQtyError")} ${product.minimum_order_quantity}`,
					"error",
					this
				);
			}

			// Minimum order amount check
			if (product.minimum_order_amount && totalPrice < product.minimum_order_amount) {
				return this.$services.helpers.notification(
					`${this.$t("customerMinimumOrderAmountError")} ${product.minimum_order_amount}`,
					"error",
					this
				);
			}

			// Check if discount applies within scheduled campaign dates
			if (product.discounted_price > 0) {
				if (product.scheduled_campaign_start_date && product.scheduled_campaign_end_date) {
					let startDate = moment(product.scheduled_campaign_start_date).format("YYYY-MM-DD");
					let endDate = moment(product.scheduled_campaign_end_date).format("YYYY-MM-DD");
					if (currentDate >= startDate && currentDate <= endDate) {
						productLoad.apply_discount = true;
					}
				}
			}

			// Check if custom row text should be added
			if (product.allow_custom_row_text === true) {
				window.Bus.$emit("open-custom-row-text", { data: product, qty });
			}

			// Add product to cart if quantity is valid
			if (productLoad.qty) {
				this.$store.commit("customer/ADD_CART_PRODUCT", productLoad);
			}

			// Emit success message
			window.Bus.$emit("message", {
				data: `${qty} ${this.$t("itemAdd")}`,
			});

			// Reset input field to zero
			window.Bus.$emit("revert-input-to-zero");
		},

		disabledFields: function (status) {
			if (getUserSetting()) {
				if (status === true) {
					let fields = getUserSetting();
					let hidden_fields = fields.hidden_fields;
					let visible_fields = fields.visible_fields;
					visible_fields.map((item) => {
						this.hidden_fields[item] = false;
					});
					hidden_fields.map((item) => {
						this.hidden_fields[item] = true;
					});
				} else {
					Object.keys(this.hidden_fields).map((item) => {
						this.hidden_fields[item] = false;
					});
				}
			}
		},
		onChangeInput(mode) {
			this.num = mode;
		},
		hasDiscount() {
			let currentDate = moment().format("YYYY-MM-DD");
			if (
				this.product.scheduled_campaign_start_date !== null &&
				this.product.scheduled_campaign_end_date !== null &&
				this.product.scheduled_campaign_start_date !== "" &&
				this.product.scheduled_campaign_end_date !== ""
			) {
				let startDate = moment(
					this.product.scheduled_campaign_start_date
				).format("YYYY-MM-DD");
				let endDate = moment(
					this.product.scheduled_campaign_end_date
				).format("YYYY-MM-DD");
				if (currentDate >= startDate && currentDate <= endDate) {
					return true;
				} else {
					return false;
				}
			} else {
				return false;
			}
		},
		addProductCart() {
			if (!this.product.allow_backorder_or_presale && parseInt(this.product.quantity_in_stock) <= 0) {
				this.$services.helpers.notification(this.$t('outOfStock'), 'error', this);
				return;
			}

			if (this.num > 0 && (this.product.allow_backorder_or_presale || parseInt(this.product.quantity_in_stock) > 0)) {
				this.handleAddToCart(this.product, this.num);
				this.num = 0;
			} else {
				this.$store.commit('customer/REMOVE_CART_PRODUCT', {
				product: this.product,
				remove: true,
				qty: this.num,
				});
				window.Bus.$emit('message', { data: this.$t('itemRemove') });
				this.prevNum = 0;
			}
			},
		showImages() {
			if (this.productImages.length > 0) {
				this.showImage = true;
				this.$viewerApi({
					images: this.productImages,
				});
			} else {
				this.showImage = false;
			}
		},
		handleChange(e) {
			if (e === undefined) {
				this.num = 0;
			} else {
				this.num = e;
			}
		},
		openProductModal(data) {
			window.Bus.$emit("open-product", data);
		},
		openProductDetail(itemID) {
			this.$router.push({
				name: "single-product",
				params: {
					company_id: this.$route.params.company_id,
					product_id: itemID,
				},
			});
		},
	},
	created() {},
};
</script>

<style lang="scss" scoped>
.button-action-pos {
	margin-top: 28px;
}

.carousel-cell {
	width: 100%; /* full width */
	height: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.carousel.is-fullscreen .carousel-cell {
	height: 100%;
}

.carousel-cell img {
	display: block;
	max-height: 100%;
	max-width: 100%;
	object-fit: contain; /* Ensure the image maintains its aspect ratio */
	margin: auto; /* Center the image horizontally and vertically */
}

.article_display_height {
	height: 190px;
}

.ribbon {
	font-size: 10px !important;
	width: 27%;
	right: -28px;
	height: 18px;
	position: absolute;
	background: #1e3a8a;
	color: #fff;
	text-align: center;
	transform: rotate(45deg);
	top: 5px;
}

.ribbon:before,
.ribbon:after {
	content: "";
	position: absolute;
	display: block;
	bottom: -1em;
	z-index: -1;
}

.ribbon:before {
	left: -2em;
	border-right-width: 1.5em;
	border-left-color: transparent;
}

.ribbon:after {
	right: -2em;
	border-left-width: 1.5em;
	border-right-color: transparent;
}

.ribbon .ribbon-content:before,
.ribbon .ribbon-content:after {
	content: "";
	position: absolute;
	display: block;
	border-style: solid;
	border-color: #1e3a8a transparent transparent transparent;
	bottom: -1em;
}

.ribbon .ribbon-content:before {
	left: 0;
	border-width: 1em 0 0 1em;
}

.ribbon .ribbon-content:after {
	right: 0;
	border-width: 1em 1em 0 0;
}

.recommended-img {
	width: 29%;
	position: absolute;
	right: -18px;
	transform: rotate(45deg);
	top: -5px;
}

.preorder-item {
	background-color: #ffff00;
	color: #000000;
	padding: 5px 10px;
	border-radius: 4px;
	font-weight: bold;
	display: inline-block;
}

.height-card {
	/* Adjust the height of the card if necessary */
}
</style>

