<template>
	<tr class="bg-white rounded p-1 shadow-sm roboto-font">
		<td class="cursor-pointer" v-on:click="showImages">
			<div class="flex flex-col w-40 list-article-view">
				<el-carousel :interval="5000" indicator-position="none">
					<el-carousel-item v-for="(item, i) in product.images" :key="i">
                        <span class="img">
								<ImageCard :source="item.image" :custom_class="'carousel-cell-image'"></ImageCard>
                        </span>
					</el-carousel-item>
				</el-carousel>
			</div>
		</td>
		<td class="cursor-pointer" v-on:click="openProductDetail(product.id)"><span>{{ product.article_number }}</span>
		</td>
		<td class="cursor-pointer" v-on:click="openProductDetail(product.id)"><span>{{
				cleanData(product.description)
			}}</span></td>
		<td class="cursor-pointer" v-on:click="openProductDetail(product.id)">
				<div v-if="GET_COMPANY.hide_stock_quantity === false && hidden_fields.stock_quantity === false">
				  <span v-if="product.allow_backorder_or_presale" class="preorder-item">
					{{ $t('preOrderItem') }}
				  </span>
				  <span v-else-if="parseInt(product.quantity_in_stock) >= parseFloat(GET_COMPANY.threshold_product_visibility)">
					<span>{{ $t('disposableQty') }}:</span>
					<span class="ml-1">{{ product.quantity_in_stock }}</span>
				  </span>
				  <span v-else>(<span class="font-semibold">{{ $t('unavailable') }}</span>)</span>
				</div>
		</td>
		<td class="cursor-pointer" v-on:click="openProductDetail(product.id)">
			<span class="text-gray-500" v-if="hasDiscount() === true && hidden_fields.discounted_price === false">{{
					$services.helpers.formatPrice(cleanData(product.discounted_price))
				}} :- </span>
			<span class="text-gray-500" v-if="hasDiscount() === false && hidden_fields.sales_price === false">{{
					$services.helpers.formatPrice(cleanData(product.sales_price))
				}} {{ product.currency }} </span>
			<div v-if="product.hasOwnProperty('standard_price')">
				{{ $t('standard_price') }}: {{
					product.standard_price
				}}
			</div>
		</td>
		<td>
            <span>
				<input-number @onchange="onChangeInput" :num="num"/>
            </span>
		</td>
		<td>
		
			<button
			class="border-2 hover:bg-yellow-500 hover:text-white border-yellow-500 px-4 py-2 text-yellow-500 rounded-md"
			@click="addProductCart">
			{{ $t('buyProduct') }}
		</button>

		</td>
	</tr>

</template>

<script>
import {mapGetters} from 'vuex'
import moment from "moment";
import ImageCard from "../../../customComponent/ImageCard";
import inputNumber from "./inputNumber";
import {BUYER_HIDDEN_FIELDS} from "../../../services/constant";
import {getUserSetting} from "../../../services/storage-window";
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import Vue from 'vue'

Vue.use(VueViewer)
export default {
	name: 'article-component',
	components: {ImageCard, inputNumber},
	props: {
		product: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			addingToCart: false,
			imageBaseServerURL: 'https://api.b2bportalen.se/media/',
			num: 0,
			prevNum: null,
			flickityOptions: {
				initialIndex: 3,
				prevNextButtons: true,
				pageDots: false,
				wrapAround: false,
				draggable: '>1',
				fullscreen: true,

				// any options from Flickity can be used
			},
			hidden_fields: {...JSON.parse(JSON.stringify(BUYER_HIDDEN_FIELDS))},
			productImages: []
		}
	},
	computed: {
		...mapGetters({
			GET_ARTICLES: 'customer/GET_ARTICLES',
			CART_PRODUCT: 'customer/CART_PRODUCT',
			GET_COMPANY: 'customer/GET_COMPANY_SETTINGS',
			BUYER_SETTINGS: 'customer/GET_USER_SETTINGS',
			HIDE_FIELD_STATUS: 'customer/GET_USER_SETTINGS_HIDE_FIELD_STATUS'
		})
	},
	watch: {},
	mounted() {
		window.Bus.$on('revert-input-to-zero', e => {
			this.num = 0
		})
		this.disabledFields(this.HIDE_FIELD_STATUS)
		console.log('heeeeeeee')
		window.Bus.$on('trigger-hidden-field-event', e => {
			this.disabledFields(e)
		})
		this.productImages = []
		if (this.product.images.length > 0) {
			this.product.images.map(item => {
				this.productImages.push(item.image)
			})
		}
	},
	methods: {
		showImages() {
			if (this.productImages.length > 0) {
				this.$viewerApi({
					images: this.productImages,
				})
			}

		},
		onChangeInput(mode) {
			this.num = mode
		},
		handleInput() {
			if (parseInt(this.num) === undefined || parseInt(this.num) === null || isNaN(parseInt(this.num))) {
				this.num = 1
			}
		},
		addProductCart() {
			let qty = this.num || 1;  // Default quantity to 1 if num is not set
			let currentDate = moment().format("YYYY-MM-DD");
			let productLoad = {
				product: this.product,
				qty,
				apply_discount: false,
			};

			// Check stock threshold and notify if out of stock
			if (this.GET_COMPANY.apply_stock_threshold === true && !this.product.allow_backorder_or_presale) {
				if (parseFloat(this.product.quantity_in_stock) <= parseFloat(this.GET_COMPANY.threshold_product_visibility)) {
					this.$services.helpers.notification(this.$t('outOfStock'), 'error', this);
					return false;
				}
			}

			// Out-of-stock check
			if (!this.product.allow_backorder_or_presale && parseFloat(this.product.quantity_in_stock) <= 0) {
				this.$services.helpers.notification(this.$t('outOfStock'), 'error', this);
				return false;
			}

			const totalPrice = parseFloat(this.product.sales_price * qty);

			// Minimum order quantity check
			if (this.product.minimum_order_quantity && qty < this.product.minimum_order_quantity) {
				return this.$services.helpers.notification(
					`${this.$t("customerMininmumOrderQtyError")} ${this.product.minimum_order_quantity}`,
					"error",
					this
				);
			}

			// Minimum order amount check
			if (this.product.minimum_order_amount && totalPrice < this.product.minimum_order_amount) {
				return this.$services.helpers.notification(
					`${this.$t("customerMinimumOrderAmountError")} ${this.product.minimum_order_amount}`,
					"error",
					this
				);
			}

			// Check if discount applies within scheduled campaign dates
			if (this.product.discounted_price > 0) {
				if (this.product.scheduled_campaign_start_date && this.product.scheduled_campaign_end_date) {
					let startDate = moment(this.product.scheduled_campaign_start_date).format("YYYY-MM-DD");
					let endDate = moment(this.product.scheduled_campaign_end_date).format("YYYY-MM-DD");
					if (currentDate >= startDate && currentDate <= endDate) {
						productLoad.apply_discount = true;
					}
				}
			}

			// Check if custom row text should be added
			if (this.product.allow_custom_row_text === true) {
				window.Bus.$emit("open-custom-row-text", { data: this.product, qty });
			}

			// Add product to cart if quantity is valid
			if (productLoad.qty) {
				this.$store.commit("customer/ADD_CART_PRODUCT", productLoad);
			}

			// Emit success message
			window.Bus.$emit("message", {
				data: `${qty} ${this.$t("itemAdd")}`,
			});

			// Reset input field to zero
			window.Bus.$emit("revert-input-to-zero");
		},
		handleChange(e) {
			if (e === undefined) {
				this.num = 0
			} else {
				this.num = e
			}
		},
		hasDiscount() {
			let currentDate = moment().format('YYYY-MM-DD')
			if (this.product.scheduled_campaign_start_date !== null && this.product.scheduled_campaign_end_date !== null && this.product.scheduled_campaign_start_date !== '' && this.product.scheduled_campaign_end_date !== '') {
				let startDate = moment(this.product.scheduled_campaign_start_date).format('YYYY-MM-DD')
				let endDate = moment(this.product.scheduled_campaign_end_date).format('YYYY-MM-DD')
				if (currentDate >= startDate && currentDate <= endDate) {
					return true
				} else {
					return false
				}
			} else {
				return false
			}
		},
		openProductModal(data) {
			window.Bus.$emit('open-product', data)
		},
		openProductDetail(itemID) {
			this.$router.push({
				name: 'single-product',
				params: {company_id: this.$route.params.company_id, product_id: itemID}
			})
		},

		cleanData: function (name) {
			if (['nan'].includes(name)) {
				return ''
			} else {
				return name
			}

		},
		disabledFields: function (status) {
			if (getUserSetting()) {
				if (status === true) {
					let fields = getUserSetting() || {}
					let hidden_fields = fields.hidden_fields
					let visible_fields = fields.visible_fields
					visible_fields.map(item => {
						this.hidden_fields[item] = false
					})
					hidden_fields.map(item => {
						this.hidden_fields[item] = true
					})
				} else {
					Object.keys(this.hidden_fields).map(item => {
						this.hidden_fields[item] = false
					})
				}

			}


		},
	}
}
</script>


<style lang="scss" scoped>

.height-card {
    height: 500px !important; /* Example of increasing card height */
}

.carousel-cell img {
    display: block;
    max-height: 70%;
    max-width: 70%;
    margin-top: -20px; /* Move the image slightly up */
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    background-color: #ffffff;
    border-radius: 5px;
}
.rm-width {
	width: unset !important;
}

.table th, .table td {
	border: unset !important;
}

.unset-pad {
	padding: .375rem 1.5rem !important;
}

.el-input-number {
	width: 130px !important;
	height: 10px;
}

.medium-zoom-image {
	cursor: pointer;
	cursor: zoom-in;
	position: absolute;
	transition: transform .3s cubic-bezier(.2, 0, .2, 1) !important;
	z-index: 99;
}

.input-el {
	border: 1px solid #f1eded;
	width: 54px;
	height: 32px;
	text-align: center;
}



.increase-button {
	width: 28px;
	font-size: 12px;
	background: #f5f7fa;
	height: 32px;
	padding: 8px;
	border: 1px solid #f5f7fa;
	border-radius: 0px;
}

.preorder-item {
	background-color: #ffff00;
	color: #000000;
	padding: 5px 10px;
	border-radius: 4px;
	font-weight: bold;
	display: inline-block;
}

.height-card {
	height: 500px; /* Increase the height of the card */
}

</style>


