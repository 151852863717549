<template>
	<div id="accordion" class="border-solid category-header bg-white">
		<div class="flex justify-content-between">
			<div>
				<p class="mb-0 text-base  col-span-1 ml-0 cursor-pointer" style="font-size: 13px;"
				   v-on:click="filterCategory">
					{{ category.name }}
				</p>
			</div>
			<div>
				<div class="mr-2 flex">
					<span class="mr-2">({{ category.total_articles }})</span>

					<!--					<span class="col-span-1 relative top-1" v-if="openChild === false" v-on:click="openChildCategory"> <svg width="14"-->
					<!--																											 height="14"-->
					<!--																											 viewBox="0 0 14 14"-->
					<!--																											 fill="none"-->
					<!--																											 xmlns="http://www.w3.org/2000/svg">-->
					<!--<path fill-rule="evenodd" clip-rule="evenodd"-->
					<!--	  d="M13.4531 6.46946C13.4854 10.0534 10.5959 12.9955 7.01198 13.0278C3.42803 13.0601 0.485961 10.1706 0.453653 6.58665C0.421344 3.00269 3.31084 0.0606275 6.8948 0.0283194C10.4787 -0.00398869 13.4208 2.88551 13.4531 6.46946ZM12.4782 6.47825C12.4507 3.43183 9.95001 0.975817 6.90358 1.00328C3.85716 1.03074 1.40115 3.53143 1.42861 6.57786C1.45608 9.62429 3.95677 12.0803 7.00319 12.0528C10.0496 12.0254 12.5056 9.52468 12.4782 6.47825ZM9.54943 5.91157L7.3138 8.19771C7.22298 8.29019 7.0993 8.34265 6.96995 8.34382C6.83996 8.34499 6.71535 8.29476 6.62288 8.20394L4.34704 5.95847C4.25129 5.86443 4.20272 5.74006 4.2016 5.61591C4.20047 5.49112 4.24679 5.36525 4.34213 5.26948C4.53151 5.07797 4.83961 5.07584 5.03112 5.26457L6.95934 7.16607L8.85231 5.23012C9.04039 5.03797 9.34912 5.03454 9.54192 5.22261C9.73537 5.41067 9.73685 5.71877 9.54943 5.91157Z"-->
					<!--	  fill="black" fill-opacity="0.2"/>-->
					<!--</svg>-->

					<!--			</span>-->
					<!--					<span class="col-span-1 relative top-1" v-if="openChild === true" v-on:click="openChildCategory">-->
					<!--				<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">-->
					<!--<path fill-rule="evenodd" clip-rule="evenodd"-->
					<!--	  d="M0.454103 6.65303C0.401871 3.06932 3.27497 0.111231 6.85869 0.0589991C10.4424 0.00676751 13.4005 2.87986 13.4527 6.46358C13.505 10.0473 10.6319 13.0054 7.04814 13.0576C3.46442 13.1098 0.506334 10.2368 0.454103 6.65303ZM1.429 6.63883C1.4734 9.68505 3.9877 12.1271 7.03393 12.0827C10.0802 12.0383 12.5222 9.52402 12.4778 6.47779C12.4334 3.43157 9.91912 0.989498 6.8729 1.0339C3.82667 1.07829 1.3846 3.5926 1.429 6.63883ZM4.36084 7.18922L6.58373 4.89068C6.67403 4.7977 6.79742 4.74455 6.92675 4.74266C7.05674 4.74077 7.18163 4.79031 7.2746 4.88061L9.56288 7.1134C9.65916 7.20691 9.70842 7.331 9.71023 7.45514C9.71205 7.57993 9.66643 7.70605 9.57162 7.80235C9.38331 7.99491 9.07523 7.99875 8.88268 7.81109L6.94392 5.92034L5.06173 7.86678C4.87473 8.05997 4.56602 8.06512 4.37218 7.87813C4.17768 7.69114 4.17449 7.38305 4.36084 7.18922Z"-->
					<!--	  fill="black" fill-opacity="0.2"/>-->
					<!--</svg>-->

					<!--			</span>-->
				</div>
			</div>

		</div>
		<div class="collapse" :class="{'show': openChild === true}" v-if="openChild === true">
			<div class="category-sub-header cursor-pointer" v-for="(item,key) in category.child_node" :key="key"
				 :class="{'border-bottom': key+1 < category.child_node.length}" v-on:click="filterChildCategory(item)">
				<div class="subcategory">
					<span style="font-size: 12px">{{ item.name }} <i class="ml-1 mr-1 not-italic"
																	 v-if="item.subcategory.length > 0"> >> </i> </span>
					<span class="font-normal mr-2" style="font-size: 12px" v-for="(sub,k) in item.subcategory"
						  :key="k">{{ sub.name }}<i class="ml-1 mr-1 not-italic"
													v-if="k+1 !== item.subcategory.length"> >></i> </span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "categoryTree",
	props: {
		category: {
			type: Object
		}
	},
	data: function () {
		return {
			checked: false,
			openChild: false,

			showHere: false,
			categoryObj: {}
		}
	},
	mounted() {
		this.categoryObj = this.category
		window.Bus.$on('remove-child-category', e => {
			let childIndex = this.categoryObj.child_node.findIndex(item => item.id === e.id)
			if (childIndex > -1) {
				this.categoryObj.child_node.splice(childIndex, 1)
			}
		})
		window.Bus.$on('remove-child-sub-category', e => {
			let childIndex = this.categoryObj.child_node.findIndex(child => child.id === e.child_category)
			if (childIndex > -1) {
				let childObj = this.categoryObj.child_node[childIndex]
				let subChildIndex = childObj.subcategory.findIndex(subIn => subIn.id === e.id)
				if (subChildIndex > -1) {
					childObj.subcategory.splice(subChildIndex, 1)
					this.categoryObj.child_node[childIndex] = childObj
				}
			}
		})
		window.Bus.$on('update-child-sub-category', e => {
			this.categoryObj.child_node.map(item => {
				let subIndex = item.subcategory.findIndex(subIn => subIn.id === e.id)
				if (subIndex > -1) {
					item.subcategory[subIndex].name = e.name
				}
			})
		})
	},
	methods: {
		openChildCategory: function () {
			this.openChild = !this.openChild
		},
		filterCategory: function () {
			this.openChildCategory()
			if (this.openChild === true) {
				this.$emit('filterCategory', {id: this.category.id})
			}

		},
		filterChildCategory: function (data) {
			this.$emit('filterChildCategory', {id: data.id, category_id: this.category.id})
		},
	}
}
</script>

<style scoped>
.category-header {
	/*padding-top: 7px;*/
	/*padding-bottom: 7px;*/
	padding-left: 13px;
	/*border: 1px solid rgb(243 244 246);*/
}

.category-sub-header {
	padding-top: 12px;
	padding-bottom: 7px;
	padding-left: 31px;

}

.border-bottom {
	border-bottom: 1px solid rgb(243 244 246) !important;
}

.span-icon {
	font-size: 16px;
	position: relative;
	top: 3px;
}
</style>
